import styled from 'styled-components';

export const PortfolioStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  font-family: ${(props) => props?.theme.fonts.primary};
  font-size: 12px;
  color: ${(props) => props?.theme.colors.black};
  margin-bottom: 100px;

  strong {
    font-family: ${(props) => props?.theme.fonts.secondary};
    color: ${(props) => props?.theme.colors.grey};
  }

  ul {
    color: ${(props) => props?.theme.colors.black};
    list-style: decimal;
    padding-left: 12px;
  }

  h2 {
    color: ${(props) => props?.theme.colors['bg-dark']};
  }

  .portfolio__button {
    padding: 20px;
  }

  .img-content {
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 800px) {
    display: flex;
    flex-direction: row;
    max-width: 980px;
    margin: auto;
    padding-bottom: 100px;

  .img-content {
    position: relative;
   height: 200px;
   width: 100%;
    img{
      object-fit: contain;
    }
   
  
    

  }

  @media (min-width: 1030px) {
  }
`;
