import styled from 'styled-components';

export const LandingStyle = styled.div`
  background: ${(props) => props?.theme.colors.original};
  font-family: ${(props) => props?.theme.fonts.secondary};

  .portfolio {
    max-width: 980px;
    margin: auto;
  }

  #textureDiv {
    width: 400px;
    height: 400px;
    position: relative;
    background-color: #b5a642; /* Base flat color */
    overflow: hidden;
  }

  .speck {
    position: absolute;
    border-radius: 50%;
    background-color: #c6c3c0; /* Color of speckles */
    opacity: 0.8;
  }

  section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .section-slider {
    display: none;
  }

  .main-external {
    display: none;
  }
  .main-external-footer {
    margin-top: 50px;
    color: ${(props) => props?.theme.colors.white};
  }
  .sectionVsCode {
    margin: auto;
    max-width: ${(props) => props?.theme.breakpoints.tablet};
  }

  .sliderView {
    display: none;
  }

  .main {
    color: ${(props) => props?.theme.colors['bg-dark']};

    &__legend {
      padding: 20px;

      &--image {
        width: 150px;
        height: auto;
        margin: auto;
        /* position: absolute;
        z-index: 3; */

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .control-button {
    display: flex;
    justify-content: space-between;
    margin: auto;
    gap: 20px;
    list-style: none;
    margin-top: 100px;
    max-width: 400px;

    &__login,
    &__register {
      color: ${(props) => props?.theme.colors.white};
      background-color: ${(props) => props?.theme.colors.black};
      padding: 15px 10px;
      width: 100%;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        transition: background-color 400ms;
        background-color: ${(props) => props?.theme.colors.red};
        color: white;
      }
    }
  }

  .hidden {
    display: none;
  }

  .display {
    margin-bottom: 20px;
    max-width: 400px;
    margin: auto;
  }

  .findUs {
    display: none;
  }

  .section-news {
    padding-left: 20px;
    padding-right: 20px;
    display: grid;
    margin-top: 4rem;

    &__youtube {
      width: 100%;
    }
  }

  .facebook {
    color: ${(props) => props?.theme.colors.black};
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 30px;
  }

  .sectionVlog {
    background: ${(props) => props?.theme.colors['original-light']};
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .sectionPrograms {
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .pentagon {
    position: absolute;
    margin-top: 20%;
    opacity: 0.4;
  }

  @media screen and (min-width: 500px) {
    .main {
      &__legend {
        padding: 20px;
        position: relative;
        overflow: hidden;

        &--image {
          position: absolute;
          z-index: 3;
          bottom: 0px;
          right: -50px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .sliderView {
      display: block;
    }
    .control-button {
      width: 470px;
    }
    .sectionPrograms {
      padding-left: 20px;
      &__items {
        justify-content: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
      }
    }
  }

  @media screen and (min-width: 1030px) {
    .main-external-footer {
      display: none;
    }

    .findUs {
      display: block;
      color: ${(props) => props?.theme.colors['bg-dark']};
      font-size: 0.7rem;
      font-weight: 300;
      text-align: center;
      margin-top: 40px;
    }

    .main-external {
      display: block;
      width: 300px;
    }

    .containerjuan {
      position: relative;

      &__juan {
        position: absolute;
        z-index: 3;
        width: 150px;
        height: auto;
        bottom: -20px;
        right: -30px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .main {
      display: flex;
      width: 1030px;
      margin: auto;

      &__legend {
        display: none;
      }
    }
    .control-button {
      width: 300px;
    }
    .order-1 {
      order: 2;
    }
    .section-news {
      display: none;
    }

    .section-slider {
      display: block;
      padding-left: 0;
      order: 1;
      min-width: 700px;
    }

    .sectionVlog,
    .sectionPrograms {
      margin: auto;
      width: 1030px;
      padding: 0;
      background-color: transparent;
    }

    .sectionPrograms {
      &__items {
        gap: 50px;
      }
    }
  }
`;
