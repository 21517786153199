import React, { useEffect, useState } from 'react';
import { PortfolioStyle } from './Portfolio';

import { PubicNews } from '../../@types/types';
import { Link } from 'react-router-dom';
import { createComponent } from '@lit/react';
import { Button } from '@juanpumpkinpie/gsx/dist/my-button.js';

type MyElementComponentProps = any;

export const MyElementComponent = createComponent<MyElementComponentProps>({
  tagName: 'my-button',
  elementClass: Button,
  react: React,
});

export default function Portfolio() {
  return (
    <PortfolioStyle>
      <div className="img-content">
        <img src="./bgbg.jpg" alt="bgbg" />
      </div>
      <div>
        <h2>Verse to verse</h2>
        <p>
          Our platform is an innovative tool designed for those seeking to
          understand and delve deeper into the teachings of the Bible in a
          comprehensive way. We offer a verse-by-verse review of the Scriptures,
          enriched with explanations that address spiritual, human, and, in some
          cases, geographical perspectives, allowing the texts to be
          contextualized more broadly and meaningfully.
          <br /> <br />
        </p>
        <h2>Technical facts:</h2>
        <ul>
          <li>
            <strong>Modern and efficient framework:</strong> Built with Angular,
            the platform offers a fast, responsive, and device-adaptable
            interface.
          </li>
          <li>
            <strong>Scalable and reliable infrastructure:</strong> Deployed on
            AWS (Amazon Web Services), it provides a robust and flexible
            foundation to handle high traffic volumes and storage needs.
          </li>
          <li>
            <strong>Automated testing:</strong> We ensure software quality
            through Cypress, an advanced system for end-to-end testing that
            guarantees flawless functionality in every user interaction.
          </li>
          <li>
            <strong>Secure authentication:</strong> Includes advanced
            authentication systems to protect user privacy and data.
          </li>
          <li>
            <strong>Communication management:</strong> Features automated email
            integration, enabling smooth communication for notifications,
            updates, and activity tracking.
          </li>
          <li>
            <strong>Dynamic interactions:</strong> Functionalities such as user
            chats, "likes," and interactive contributions to Bible verses create
            a participatory environment that fosters learning and a sense of
            community.
          </li>
        </ul>
        {/* <a
          href="https://angular-17-khaki.vercel.app/"
          target="_blank"
          rel="noreferrer"> */}
        <div className="portfolio__button">
          <MyElementComponent
            labelTemplate="Open site"
            colors="wh4u"
            type="button"></MyElementComponent>
        </div>
        {/* </a> */}
      </div>
    </PortfolioStyle>
  );
}
