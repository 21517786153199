import styled from 'styled-components';

export const TheNews = styled.div`
  .news-content {
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Corrected property name */
    -webkit-box-orient: vertical; /* Corrected property name */
    overflow: hidden;
    font-family: ${(props) => props?.theme.fonts.primary};
    font-size: 16px; /* Corrected property name */
    section {
      padding: 0;
    }

    &__button {
      margin-top: 20px;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
  .two {
    display: none;
  }
  .readMore {
    padding: 5px;
    color: ${(props) => props?.theme.colors.white};
    background-color: ${(props) => props?.theme.colors.original};
    font-family: ${(props) => props?.theme.fonts.secondary};
    border: none;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .news {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 4px;
    height: auto;
    width: 100%;

    &__article {
      padding: 15px;
      margin-top: 1rem;
      font-size: 13px;
      border-radius: 1rem;
      font-family: ${(props) => props?.theme.fonts.secondary};
      font-weight: 300;

      color: #828282;
    }

    &__title {
      color: ${(props) => props?.theme.colors.black};
      font-family: ${(props) => props?.theme.fonts.secondary};
      text-transform: uppercase;
      font-size: 1rem;
    }

    &__tag {
      background-color: ${(props) => props?.theme.colors.red};
      padding: 2px;
      color: ${(props) => props?.theme.colors.white};
    }

    &__imageNews {
      max-height: 300px;
      width: 100%;
      object-fit: cover;
      margin-bottom: 5px;
    }
  }

  @media (min-width: 700px) {
    width: 100%;

    .news {
      &__article {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        background: none;
        color: ${(props) => props?.theme.colors['bg-dark']};
      }

      &__section {
        color: ${(props) => props?.theme.colors.black};
        padding: 20px;
        height: 300px;
        margin-top: 85px;
      }

      &__imageNews {
        height: 300px;
      }
    }
  }

  @media (min-width: 1030px) {
    display: flex;
    gap: 30px;
    position: relative;

    .news-content {
      font-size: 18px;
    }

    .two {
      display: inline-block;
      position: sticky;
      top: 0;
      width: 450px;
      font-family: ${(props) => props?.theme.fonts.secondary};
      font-size: 1.3rem;
      padding: 10px;
      background-color: ${(props) => props?.theme.colors.original};
      height: 800px;
      margin-right: 15px;
      margin-bottom: 15px;
      border-radius: 4px;
    }

    .news {
      width: 1fr;
      display: flex;

      &__article {
        background: none;
        color: ${(props) => props?.theme.colors.black};
        border-bottom: 1px solid ${(props) => props?.theme.colors['bg-dark']};
        border-radius: 0;
      }
      &__title {
        font-size: 1.2rem;
      }
    }
  }
`;
