import styled from 'styled-components';

export const SideBarStyles = styled.div`
  padding: 20px;

  .sidebar-container--id.current {
    background: ${(props) => props?.theme.colors['o-soft']};
    color: ${(props) => props?.theme.colors.original};
  }

  @keyframes pulse {
    to {
      scale: 0.9;
    }
  }

  h3,
  h2 {
    font-family: ${(props) => props?.theme.fonts.secondary};
  }

  .notPermited {
    color: ${(props) => props?.theme.colors.black};
    display: flex;
    justify-content: center;
  }

  .sidebar-container {
    position: relative;
    padding: 30px 0px;

    &--id {
      position: relative;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background-color: ${(props) => props?.theme.colors.red};
      }
    }

    &--description {
      color: ${(props) => props?.theme.colors.red};
    }
  }

  .menu-item-b {
    margin-bottom: 50px;
  }

  .videoSelected {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__info {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .videoProjector {
    width: 100%;
    height: 100%;
    border: none;
    background-color: none;
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.tablet}) {
    .videoSelected {
      min-height: 600px;
      margin: auto;
      margin-buttom: 20px;
    }
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.laptop}) {
    .sidebar-container--id.current {
      background: ${(props) => props?.theme.colors.red};
      color: ${(props) => props?.theme.colors.original};
      animation: pulse 1100ms infinite alternate;
      width: 100%;
    }
    .videoSelected {
      width: 740px;
      min-height: 500px;
      margin: auto;
      margin-buttom: 20px;

      &__info {
        max-width: 1000px;
        margin: auto;
        padding: 0px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .sidebar-container {
      display: grid;
      grid-template-columns: [first] 1fr 2fr 2fr [end] 120px;
      gap: 20px;
      height: auto;
      width: 1000px;
      margin: auto;

      &--id {
        font-size: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${(props) => props?.theme.colors['original-light']};
      }
    }

    .menu-item-a {
      grid-column-start: 2;
      grid-column-end: 4;
      width: 100%;
      min-height: 100px;
    }

    .menu-item-b {
      grid-column-start: 4;
      grid-column-end: 5;
      width: 100%;

      &__content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;
        justify-content: center;
        height: 100%;
      }
    }
  }
`;
